(function($) {

  // Setup
  // ----------

  // The fix is for webkit browsers only

  if(!(/WebKit/.test(navigator.userAgent))) {
    // return functions that do nothing but support chaining
    $.fn.fixSVGStack = function() { return this; };
    //$.fn.fixSVGStackBackground = function() { return this; };
    return;
  }

  // The Fix
  // ----------

  // Caches XML of SVG Elements (less parsing)
  var cache = {};

  // Reads SVG Stack via Ajax and returns one element encoded data-uri.
  var i = 0;
  function getdataURIFromStack(url, cb) {

    if(url in cache) {
      cb(cache[url]);
    }

    // `url` must be in the form filename.svg#id
    var parts = url.split('#');
    if(parts.length !== 2) {
      cb(false);
    }

    var processStack = function(xmlText) {

      var xml = (new window.DOMParser()).parseFromString(xmlText, "text/xml")
      // `parts[1]` contains id
      var svg = xml.getElementById(parts[1]);
      if(svg == null) {
        cache[url] = false;
        cb(false);
      }
      // iOS Safari fix:
      // Firefox uses viewBox and can't scale SVGs when width and height
      // attributes are defined.
      // Safari on iOS needs width and height to scale properly
      if (svg) {
		  var viewBox = svg.getAttribute('viewBox');
      var viewBoxData = viewBox.split(' ');

		  if(viewBox && viewBoxData.length == 4) {
			svg.setAttribute('width', viewBoxData[2]);
			svg.setAttribute('height', viewBoxData[3]);
		  }

		  var svgString = (new XMLSerializer()).serializeToString(svg);
		  var dataURI = 'data:image/svg+xml;utf-8,' + escape(svgString);
		  cache[url] = dataURI;
		  cb(dataURI);
	  }
    }

    // Ajax request, browser handles caching
    $.ajax({
        // `parts[0]` contains filename.svg
        url: parts[0],
        cache: true,
        // Read SVG as 'text', jQuerys XML Parsing is broken with SVGs
        dataType: 'text',
        success: processStack
      });
  }

  // Fix for SVG Stacks in img Tags
  $.fn.fixSVGStack = function() {
    this.each(function() {

      var $el = $(this);
      var url = $el.attr('src');

      if(url.indexOf('.svg#') === -1) {
        return;
      }
      getdataURIFromStack(url, function(dataURI) {
        if(dataURI === false) {
          return;
        }
        // Replace src with dataURI
        $el.attr('src', dataURI);
      });
    });
    return this;
  };

})(window.rossel.jquery);
