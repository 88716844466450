(function($) {
  var win = $(window);
  var body = $('body');
  var debug = true;

  var init_limit_connect = function() {
    debug && console.log('initialize -> init_limit_connect');

    var popup = {
      title: '<h2>Vous êtes déconnecté</h2><a href="#" class="btn-close"></a>',
      text: '<p><strong>Cet appareil a été déconnecté par le propriétaire du compte.</strong></p><p>Veuillez vous reconnecter sur cet appareil pour accéder à nouveau à l\'ensemble des contenus numériques du Soir.</p><p style="text-align:center;"><a href="https://login.lesoir.be/html/login?unitId=LS_ena_prod" class="btn btn-primary">Je me connecte</a></p>',
      links: '<p>Vous n\'êtes pas propriétaire de ce compte ?</p><p> Découvrez <a href="https://espace-abonnement.lesoir.be/?int_source=soirplus&int_medium=limitation_connection" target="_blank">nos offres d\'abonnement dès maintenant.</a></p>',
    };

    if (typeof $.fn.limitConnexion === 'function') {
      var htmlPopup = body.limitConnexion(popup);
      if (htmlPopup !== '') {
        body.append(htmlPopup);
        var host = window.location.hostname;
        host = host.split(".");
        host = host.slice(1);
        host = host.toString();
        host = host.replaceAll(',', '.');
        host = "."+host;
        document.cookie = 'v_limit=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;domain='+host+';';
      }
    }
  };

  var closeAlert = function() {
    debug && console.log('initialize -> closeAlert');
    $('.alert .btn-close').click(function() {
      $(this)
        .closest('.alert')
        .remove();
      return false;
    });
  };

  var init_traffic = function() {
    debug && console.log('initialize -> init_traffic');

    var trafic_links = $('.trafic__link[data-toggle="tab"]');
    trafic_links.on('shown.bs.tab', function(e) {
      var target = $(e.target).attr('href');

      var iframe = $(target).find('iframe');

      if (!iframe.hasClass('loaded')) {
        iframe.attr('src', iframe.attr('src'));
        iframe.addClass('loaded');
      }
    });
  };

  var init_old_browser = function() {
    debug && console.log('initialize -> init_old_browser');

    window.init_old_browser_message({
      minimal_browser_versions: [['MSIE', 12]], // modifier: 'old-browser'
      // title: 'old browser content',
      // content: 'old browser content',
    });
  };

  /*var displayPepite = function() {
   var pepite = $('div.gr-pepite');
   if (pepite) {
   $('.gr-media-wrapper-pepite').append(pepite);
   pepite.css({
   display: 'block'
   });
   }
   };*/

  var init_perfect_scrollbar = function() {
    debug && console.log('initialize -> init_perfect_scrollbar');

    if (!is_touch_device()) {
      var perfect_scrolls_bars = $('#gr-sidebar-left-content, #gr-12-clics-nav, .gr-search-facet .article-group, .sidebar');
      perfect_scrolls_bars.perfectScrollbar();

      // create a function for updating the scrollbars from outside (header.js)
      window.update_perfect_scrolls_bars = function() {
        perfect_scrolls_bars.perfectScrollbar('update');
      };
    }
  };

  var init_vertical_collapse_menu = function() {
    debug && console.log('initialize -> init_vertical_collapse_menu');

    $('#gr-main-menu').verticalCollapseMenu({
      class_item_with_subnav: 'expanded',
      class_item_with_subnav_open: 'has-nav-open',
      class_nav: 'nav',
      class_nav_open: 'is-open',
      close_brother_on_open: true,
    });
  };

  var init_lazyScroll = function() {
    var scrollMap = $('.gr-media--googlemap, .gr-media--waze');
    scrollMap.addClass('lazyscroll');

    scrollMap.click(function() {
      scrollMap.removeClass('lazyscroll');
    });

    scrollMap.mouseleave(function() {
      scrollMap.addClass('lazyscroll');
    });
  };

  var init_header = function() {
    debug && console.log('initialize -> init_header');
    window.init_header_params({
      header_decalage: 36,
    });
  };

  var init_direct = function() {
    le_direct_start();
  };

  var init_direct_v2 = function() {

    if($("#gr-le-direct").length){
    debug && console.log('initialize -> init_direct_v2');

    // window.direct_v2_2_start({
    //   base_url: 'http://ena-stage-master.lesoir.be/',
    //   direct_url: 'http://ena-stage-master.lesoir.be/wallyjson/wally-section/18/ledirect/50.json',
    //   sticky_url: 'http://ena-stage-master.lesoir.be/wallyjson/wally-section/18/le_direct_sticky.json',
    //   // menu_url: 'http://ena-stage.lesoir.be/dpijson/dpijsonexporter/le_direct_v2.json',
    //   interval_time: 5 * 1000,
    //   max_value_notification: 10
    // });

    $('.direct__current').addClass('is-active');

    //si dans l'url il y a "nl"
    var str = top.location.href;
    var lang = 'fr-BE';

    if(str.indexOf("nl") != -1){
      lang = 'nl-BE';
    }



      window.direct_v2_start({
      // direct_url: 'http://ena-stage-master.lesoir.be/wallyjson/wallyjsonexporter/le_direct_v2.json',

        has_date_separator: true,
        direct_link_object: window.direct_lesoir || window.direct_link,

        // sticky_path: 'api/section/le_direct_v2/18/le_direct_sticky.json',
        //base_url: 'http://ena-stage-master.lesoir.be/',
        // menu_path: 'api/product/le_direct_v2.json',

        tumbnail_height: 110,
        display_direct: null,

        interval_time: 120 * 1000,
        max_value_notification: 10,

        locale_date: lang,
      });
    }
  };

  var init_popup_paywall = function() {
    debug && console.log('initialize -> init_popup_paywall');
    // avoid display splash screen in dev mode with browsersync
    var local = window.location.hostname === 'localhost' || window.location.port === '3000';

    if (window.initPopupPaywall && !local) {
      window.initPopupPaywall({
        delay: 0,
        canBeClose: false,
        showOnScroll: true,
      });
    }
  };

  var init_owl_gallery = function() {
    debug && console.log('initialize -> init_owl_gallery');
    var articles_gallery = $('.gr-owl-gallery .owl-carousel');

    if (articles_gallery.exists()) {
      var expand = $('.gr-expand');
      var popupContent = articles_gallery.clone();

      // FULL SIZE
      expand.on('click', function() {
        var indexItem = $('.owl-thumbs .active').index(),
          popup = popupContent.clone();

        body.addClass('fixed');

        popup.insertAfter('.gr-expand');
        popup.wrap('<div class="gr-popup"></div>');
        $('.gr-popup').append('<a class="gr-close"></a>');

        popup.owlCarousel({
          items: 1,
          loop: true,
          nav: true,
          navText: ['', ''],
          dots: false,
          animateOut: 'fadeOut',
          thumbs: true,
          thumbImage: true,
          thumbContainerClass: 'owl-thumbs',
          thumbItemClass: 'owl-thumb-item',
          startPosition: indexItem,
        });
      });

      // CLOSE FULL SIZE
      $('.gr-owl-gallery').on('click', '.gr-close', function() {
        $('.gr-popup').remove();
        body.removeClass('fixed');
      });

      // GENERATE GALLERY
      articles_gallery.owlCarousel({
        thumbs: true,
        thumbImage: true,
        thumbContainerClass: 'owl-thumbs',
        thumbItemClass: 'owl-thumb-item',
        items: 1,
        loop: true,
        nav: true,
        navText: ['', ''],
        dots: false,
        animateOut: 'fadeOut',
      });
    }
  };

  var init_owl_carousel = function() {
    debug && console.log('initialize -> init_owl_carousel');
    var une_carousel = $('#gr-unes-titres .owl-carousel, .js-owl-carousel-une, #gr-article-container .owl-carousel');

    var une_carousel_region_8_4_zone_left = $('.gr-region-col-8-4 .gr-zone-inner > .pane-enalesoir-carousel-affichette .owl-carousel');
    var une_carousel_region_12_zone_left = $('.gr-region-col-12 .gr-zone-inner > .pane-enalesoir-carousel-affichette .owl-carousel');

    var sidebar_carousel = $('.gr-sidebar .owl-carousel, .gr-article-sidebar .owl-carousel');
    var articles_carousels = $('.gr-articles-carousel');
    var carousel_sidebar_wc = $('.gr-panel-carousel--wc .owl-carousel');

    var carousel_start_position = typeof carousel_settings !== 'undefined' ? (carousel_settings || 0).carousel_position || 0 : 0;

    ////////////////////////////////////////////
    // tested since owl 2.3.4 - on une page
    ////////////////////////////////////////////


    if (articles_carousels.exists()) {
      var createCarousel = function() {
        console.log('initialize -> createCarousel');

        articles_carousels.each(function() {
          var carousel = $(this);

          var big = carousel.find('.gr-articles-carousel-big');
          if (big.length === 0) {
            big = carousel.find('.list-group');
            // need to be done (not present in html)
            console.log('initialize -> createCarousel - add class owl-carousel manually');
            big.addClass('owl-carousel');
          }

          var small = carousel.find('.gr-articles-carousel-small');
          if (small.length === 0) {
            small = big.clone();
            small.insertAfter(big);
          }

          var bigOwlObject = small.data('owl.carousel');
          var smallOwlObject = small.data('owl.carousel');

          if (bigOwlObject && smallOwlObject) {
            bigOwlObject.reset();
            smallOwlObject.refresh();
          } else {
            big.addClass('gr-articles-carousel-big');
            small.addClass('gr-articles-carousel-small');

            big.owlCarousel({
              dots: false,
              thumbs: false,
              items: 1,
              nav: false,
              pagination: false,
              loop: true,
              onResize: function(event) {
                console.log('big -> onResize');
              },
            });

            small.owlCarousel({
              dots: false,
              thumbs: false,
              items: 4,
              startPosition: 1,
              nav: false,
              pagination: false,
              loop: true,
              onResize: function(event) {
                debug && console.log('small -> onResize');
              },
            });
          }

          $(this)
            .find('.owl-next')
            .off('click')
            .on('click', function(event) {
              $(this)
                .closest('.owl-controls')
                .next('.pane-content')
                .find('.list-group')
                .trigger('next.owl.carousel');
            });

          $(this)
            .find('.owl-prev')
            .off('click')
            .on('click', function(event) {
              $(this)
                .closest('.owl-controls')
                .next('.pane-content')
                .find('.list-group')
                .trigger('prev.owl.carousel');
            });
        });
      };

      createCarousel();

      // fix firefox s
      setTimeout(createCarousel, 500);
    }

    if (une_carousel_region_12_zone_left.exists()) {
      console.log('une_carousel_region_12_zone_left');

      une_carousel_region_12_zone_left.owlCarousel({
        dots: false,
        thumbs: false,
        responsive: {
          0: {
            items: 1,
            nav: true,
            pagination: false,
            startPosition: carousel_start_position,
          },
          480: {
            items: 2,
            nav: true,
            pagination: false,
            startPosition: carousel_start_position,
          },
          800: {
            items: 5,
            nav: true,
            pagination: false,
            margin: 12,
            startPosition: carousel_start_position,
          },
          1200: {
            items: 5,
            nav: true,
            pagination: false,
            margin: 12,
            startPosition: carousel_start_position,
          },
          1600: {
            items: 7,
            nav: true,
            pagination: false,
            margin: 12,
            startPosition: carousel_start_position,
          },
        },
        navElement: 'div',
        navText: ['', ''],
      });
    }

    ////////////////////////////////////////////
    // no test since owl 2.3.4
    ////////////////////////////////////////////

    if (une_carousel.exists()) {
      une_carousel.owlCarousel({
        dots: false,
        thumbs: false,
        responsive: {
          0: {
            items: 1,
            nav: true,
            pagination: false,
          },
          480: {
            items: 2,
            nav: true,
            pagination: false,
          },
          800: {
            items: 4,
            nav: true,
            pagination: false,
            margin: 12,
          },
        },
        navElement: 'div',
        navText: ['', ''],
      });
    }

    if (une_carousel_region_8_4_zone_left.exists()) {
      console.log('une_carousel_region_8_4_zone_left');
      une_carousel_region_8_4_zone_left.owlCarousel({
        dots: false,
        thumbs: false,
        responsive: {
          0: {
            items: 1,
            nav: true,
            pagination: false,
            startPosition: carousel_start_position,
          },
          480: {
            items: 2,
            nav: true,
            pagination: false,
            startPosition: carousel_start_position,
          },
          800: {
            items: 3,
            nav: true,
            pagination: false,
            margin: 12,
            startPosition: carousel_start_position,
          },
          1600: {
            items: 5,
            nav: true,
            pagination: false,
            margin: 12,
            startPosition: carousel_start_position,
          },
        },
        navElement: 'div',
        navText: ['', ''],
      });
    }

    if (carousel_sidebar_wc.exists()) {
      console.log('carousel_sidebar_wc');
      carousel_sidebar_wc.owlCarousel({
        dots: false,
        thumbs: false,
        items: 2,
        nav: true,
        pagination: false,
        navElement: 'div',
        navText: ['', ''],
        startPosition: carousel_start_position,
      });
    }

    if (sidebar_carousel.exists()) {
      console.log('sidebar_carousel');
      sidebar_carousel.owlCarousel({
        dots: false,
        thumbs: false,
        items: 1,
        nav: true,
        pagination: false,
        navElement: 'div',
        navText: ['', ''],
      });
    }

    // $(".gr-carousel").owlCarousel({
    //   items: 4,
    //   lazyLoad: true,
    //   nav: true,
    //   pagination: false,
    //   responsive: {
    //     0: {
    //       items: 1
    //     },
    //     479: {
    //       items: 2
    //     },
    //     670: {
    //       items: 3
    //     },
    //     768: {
    //       items: 4
    //     }
    //   },
    //   navText: ['<span class="glyphicon glyphicon-chevron-left"></span>', '<span class="glyphicon glyphicon-chevron-right"></span>'],
    // });
  };

  var init_slidedown = function() {
    debug && console.log('initialize -> init_slidedown');
    var slidedown = $('.js-slidedown');

    if (slidedown.exists()) {
      $('.js-slidedown .pane-heading').on('click', function() {
        $(this)
          .parent()
          .toggleClass('open');
      });
    }
  };

  var init_cookie_directive = function() {
    debug && console.log('initialize -> init_cookie_directive');
    $.cookiesDirective();
  };

  var init_fix_svg = function() {
    debug && console.log('initialize -> init_fix_svg');
    $('#logo img, .gr-default-image').fixSVGStack();
  };

  var init_help_overlay = function() {
    debug && console.log('initialize -> init_help_overlay');
    var help_overlay = [{
      id: '#gr-services-button a',
      text: 'Menu<br/><span class="gr-key">shift</span><br/>+<br/><span class="gr-key">m</span>',
      position: 'bottom',
    }, {
      id: '#gr-social-menu a',
      text: 'Suivre<br/><span class="gr-key">shift</span> + <span class="gr-key">s</span>',
      position: 'bottom',
    }, {
      id: '#gr-search-menu a',
      text: 'Recherche<br/><span class="gr-key">shift</span><br/>+<br/><span class="gr-key">r</span>',
      position: 'bottom',
    }, {
      id: '#gr-login',
      text: 'Menu utilisateur&nbsp;&nbsp;&nbsp;<span class="gr-key">shift</span> + <span class="gr-key">u</span>',
      position: 'left',
    }, {
      id: '.gr-navbar-header-center',
      text: 'Raccourci:<br/><br/><strong>Général</strong><br/><ul><li><span class="gr-key">shift</span> + <span class="gr-key">h</span> Affichage des raccourci clavier sur la page</li><li><span class="gr-key">shift</span> + <span class="gr-key">t</span> Revenir en haut de page</li></ul><br/><strong>Article</strong><br/><ul><li><span class="gr-key">&larr;</span> 1x afficher titre article précédent - 2x aller vers l\'article précédent</li><li><span class="gr-key">&rarr;</span> 1x afficher titre article suivant - 2x aller vers l\'article suivant</li><li><span class="gr-key">shift</span> + <span class="gr-key">z</span> Article en mode « Lecture zen »</li><li><span class="gr-key">shift</span> + <span class="gr-key">c</span> Commenter rapidement un article</li></ul>',
      position: 'bottom',
    }, {
      id: '.article-features-zen-reading a',
      text: 'Lecture zen<br/><span class="gr-key">shift</span><br/>+<br/><span class="gr-key">z</span>',
      position: 'bottom',
    }, {
      id: '.gr-article-prev a',
      text: '<span class="gr-key">&larr;</span>&nbsp;&nbsp;&nbsp;Article précédent<br/>(1x Titre - 2x Afficher)',
      position: 'right',
    }, {
      id: '.gr-article-next a',
      text: 'Article suivant&nbsp;&nbsp;&nbsp;<span class="gr-key">&rarr;</span><br/>(1x Titre - 2x Afficher)',
      position: 'left',
    }, {
      id: '#comment textarea',
      text: 'Commenter<br/><span class="gr-key">c</span>',
      position: 'top',
    }, {
      id: '#gr-footer',
      text: 'Haut de page<br/><span class="gr-key">shift</span><br/>+<br/><span class="gr-key">t</span>',
      position: 'top',
    }];

    $.each(help_overlay, function(index, value) {
      $(value.id)
        .attr('data-intro', value.text)
        .attr('data-position', value.position);
    });
  };

  var init_keyboard_shortcuts = function() {
    debug && console.log('initialize -> init_keyboard_shortcuts');
    // Keyboard Shortcuts
    var overk, tkk, isKey = false;
    var ikk = '';
    var title = $('.gr-article-prev .gr-article-prev-next-title');

    $(document)
      .keydown(function(e) {
        //shift
        if (e.which === 16 || e.keyCode === 16 || e.which === 37 || e.keyCode === 37 || e.which === 39 || e.keyCode === 39) {
          isKey = true;
        }
      })
      .keyup(function(e) {
        var key = e.keyCode || e.which;

        ikk += key;
        clearTimeout(tkk);
        tkk = setTimeout(function() {
          ikk = '';
        }, 1000);

        switch (ikk) {
          case '38384040373937396566':
            alert('Konami code');
            break;

          case '827379836976':
            $('html').css('transform', 'rotate(180deg)');
            break;

          case '7368686568':
            alert('God mode activate');
            body.addClass('gdmode');
            $('#gr-login-button .hello-user').html('Bonjour M. Bernard Marchant');
            break;

          case '796673876578':
            $('.gr-meta-author a').html('<i class="fa fa-space-shuttle"></i>Par Obiwan Kenobi');
            break;
        }

        if ($('input:focus').length > 0 || $('textarea:focus').length > 0 || isKey !== true) {
          isKey = false; // Si on se trouve dans un input, une textarea ou si on n'a pas pressé la touche combinaison
          return false;
        }


        // deuxième touche pressée
        switch (key) {
          // Z
          case 90:
            toggle_display_zen_mode();
            return false;
          // R
          case 82:
            $('#gr-search-menu .dropdown-toggle').dropdown('toggle');
            return false;
          // M
          case 77:
            body.toggleClass('has-sidebar');
            $('.gr-navbar-collapse-services').toggleClass('sidebar-open');
            return false;
          // S
          case 83:
            $('#gr-social-menu .dropdown-toggle').dropdown('toggle');
            return false;
          // C
          case 67:
            $('#comment-form textarea').focus();
            return false;

          // flèche gauche
          case 37:
            if (!body.hasClass('gr-tour')) {
              if (title.length && title.css('display') !== 'none') {
                window.location.href = $('.gr-article-prev a').attr('href');
              } else {
                $('.gr-article-prev-next .gr-article-prev-next-title')
                  .css('display', 'none')
                  .removeClass('gr-article-prev-next-title-visible');
                $('.gr-article-prev .gr-article-prev-next-title', $(this))
                  .delay(10)
                  .stop(true, true)
                  .fadeIn()
                  .addClass('gr-article-prev-next-title-visible');
              }
            }
            return false;

          // flèche droite
          case 39:
            if (!body.hasClass('gr-tour')) {
              if (title.length && title.css('display') !== 'none') {
                window.location.href = $('.gr-article-next a').attr('href');
              } else {
                $('.gr-article-prev-next .gr-article-prev-next-title')
                  .css('display', 'none')
                  .removeClass('gr-article-prev-next-title-visible');
                $('.gr-article-next .gr-article-prev-next-title', $(this))
                  .delay(10)
                  .stop(true, true)
                  .fadeIn()
                  .addClass('gr-article-prev-next-title-visible');
              }
            }
            return false;

          // H
          case 72:
            if (!overk) {
              body.chardinJs('start');
              overk = true;
            } else {
              body.chardinJs('stop');
              overk = false;
            }

            return false;

          // U
          case 85:
            body.toggleClass('has-sidebar');
            $('.gr-navbar-collapse-login').toggleClass('sidebar-open');
            return false;

          // T
          case 84:
            $('html, body').animate({
              scrollTop: 0,
            }, 'slow');
            return false;

          // K
          case 75:
            return false;
        }

        isKey = false; // On réinitialise
      });
  };

  var init_stick_leaderboard = function() {
    debug && console.log('initialize -> init_stick_leaderboard');
    $(window).scroll(function() {
      var adsPos = $(window).scrollTop();
      var blockAds = $('.gr-ads-leaderboard-top .pane-block');
      var adsHeight = blockAds.innerHeight();
      var headerHeight = $('#gr-navbar-header').innerHeight();

      $('.gr-ads-leaderboard-top').css({
        height: adsHeight + 'px',
      });
      blockAds.css({
        position: 'fixed',
        top: '0',
      });

      if (adsPos > headerHeight) {
        blockAds.css({
          position: 'absolute',
          top: headerHeight + 'px',
        });
      }
    });
  };
  var init_autoHeight = function() {
    debug && console.log('initialize -> init_autoHeight');
    $('.gr-autoHeight').iFrameResize([{
      checkOrigin: false,
    }]);
  };

  var select_newsletter = function() {
    debug && console.log('initialize -> select_newsletter');
    if ($('.inscription_aux_newsletters').length > 0) {
      var block = $('.blocNews'), btn = $('.boutAjout'),
        checkboxElem = $('input'), initTableVal = [];

      block.on('click', function(e) {
        if ($(this)
          .find(btn)
          .hasClass('cancel')) {
          $(this)
            .find(checkboxElem)
            .trigger('click');
          $(this)
            .find(btn)
            .removeClass('cancel');
          $(this).removeClass('selected');
          $(this)
            .find(btn)
            .html('Ajouter');
        } else {
          $(this)
            .find(checkboxElem)
            .trigger('click');
          $(this)
            .find(btn)
            .addClass('cancel');
          $(this).addClass('selected');
          $(this)
            .find(btn)
            .html('Retirer');
        }
      });

      var checkboxes = $('.inscription_aux_newsletters input[type="checkbox"]');
      checkboxes.each(function() {
        if ($(this).attr('checked')) {
          initTableVal.push('1');
        } else {
          initTableVal.push('0');
        }
        $('button[type="submit"]').prop('disabled', true);
      });

      checkboxes.on('change', function() {
        var tableVal = [];

        $('.inscription_aux_newsletters input[type="checkbox"]').each(function() {
          if ($(this).is(':checked')) {
            tableVal.push('1');
          } else {
            tableVal.push('0');
          }
        });

        if ($(initTableVal).not(tableVal).length === 0 && $(tableVal).not(initTableVal).length === 0) {
          $('button[type="submit"]').prop('disabled', true);
        } else {
          $('button[type="submit"]').prop('disabled', false);
        }
      });
    }
  };

  var init_movePremenu = function() {
    debug && console.log('initialize -> init_movePremenu');
    var menu = $('.gr-pre-menu');
    if (menu.length > 0) {
      menu.prependTo($('#gr-main-nav-content .sidebar-left'));
    }
  };

  var init_section_more = function() {
    debug && console.log('initialize -> init_section_more');

    var section_plus = $('#gr-section-plus');
    var section_more = $('#gr-sections-plus-top');

    if (window.matchMedia('(max-width: 767x)').matches) {
      // xs mediaqueries
      section_more.find('li').show();
    } else {
      section_more.sectionMore();

      var timer;
      $(window).bind('resize', function() {
        timer && clearTimeout(timer);
        timer = setTimeout(section_more.sectionMore(), 500);
      });

      win.bind('orientationchange', function(event) {
        section_more.sectionMore();
      });

      window.force_refresh_section_more = function() {
        if (section_more.exists() && typeof $.fn.sectionMore === 'function') {
          section_more.sectionMore();
        }
      };

      section_plus.find('> a').click(function(e) {
        e.preventDefault();
      });
    }
  };

  var init_tabs_waze = function() {
    debug && console.log('initialize -> init_tabs_waze');
    if ($('.gr-pane-waze').length) {
      var tabs = $('.gr-pane-waze #tabs ul > li');
      var active = tabs.filter('.active');
      var next = active.next('li');
      var toClick = next.length ? next.find('a') : tabs.eq(0).find('a');

      toClick.tab('show');

      $('.gr-pane-waze #tabs ul > li a').click(function(e) {
        e.preventDefault();
        var idBlock = $(this).attr('href');

        if (!$('iframe', idBlock).hasClass('lazyloaded')) {
          $(idBlock)
            .find('iframe')
            .addClass('lazy');
        }

        $(this).tab('show');
      });
    }
  };

  var init_drupal_js = function() {
    debug && console.log('initialize -> init_drupal_js', window.drupalScripts);

    // create drupal Object if not defined
    if (window.Drupal === undefined) {
      window.Drupal = {
        settings: {},
        behaviors: {},
        locale: {},
      };
    }

    if (window.drupalScripts && window.drupalScripts.length > 0) {
      window.drupalScripts.forEach(function(handler, index) {
        debug && console.log('initialize -> index', index);
        debug && console.log('initialize -> handler', handler);

        handler($);
      });
    }
  };

  // var init_sell_igent = function() {
  //   debug && console.log('initialize -> init_sell_igent', window.sellIgentScripts);

  //   if (window.sellIgentScripts && window.sellIgentScripts.length > 0) {
  //     window.sellIgentScripts.forEach(function(handler) {
  //       handler($);
  //     });
  //   }
  // };

  var init_scrollspy = function() {
    body.scrollspy && body.scrollspy({ target: '.gr-story-navigation' });
  };

  var init_zoom_image = function() {
    debug && console.log('initialize -> init_zoom_image');

    body.append('<div class="zoom-img-wrap"></div>');

    var figures = $('.gr-article-content figure, .gr-article-content .thumbnail.embed-responsive').not('.gr-gallery-thumbnail');
    var modal = $('.zoom-img-wrap');

    figures.each(function(index) {
      var figure = figures.eq(index);
      var img = figure.find('img')[0];

      figure.on('click', function(e) {
        // If caption is needed: <span>' + img.alt + '</span>
        modal.append('<div class="zoom-img-inner"><a class="zoom-img-close"></a><div class="zoom-img-body"><img class="media-object img-responsive" alt="' + img.alt + '" src="' + img.src + '"></div></div>');
        modal.addClass('is-shown');
      });
    });

    modal.on('click', function(e) {
      $(this).removeClass('is-shown');
      $(this)
        .children()
        .remove();
    });

    body.on('keydown', function(e) {
      if (e.keyCode === 27) {
        modal.removeClass('is-shown');
        modal.children().remove();
      }
    });
  };

  var init_edge_display_contents = function() {
    debug && console.log('initialize -> init_edge_display_contents');
    if (navigator.appVersion.indexOf('Edge') !== -1) {
      var elements = $('#gr-article .pane-node-content, #gr-article article');
      elements.contents().unwrap();
    }
  };

  var init_gallery = function() {
    debug && console.log('initialize -> init_gallery');
    window.init_gallery_owl && window.init_gallery_owl({});
  };

  var init_google_optimizer = function(){
    debug && console.log('initialize -> init_google_optimizer');
    window.init_google_optimize && window.init_google_optimize('.gr-optimize');
  }

  var main = function() {
    debug && console.log('initialize -> main');

    window.init_switcher_menu && window.init_switcher_menu($);

    init_drupal_js();
    // init_sell_igent();

    // former core initialized
    init_perfect_scrollbar();
    init_vertical_collapse_menu();
    init_lazyScroll();
    init_header();

    // theme inits
    init_section_more();
    init_popup_paywall();
    init_direct();
    // init_direct_v2();
    init_fix_svg();
    init_help_overlay();
    init_keyboard_shortcuts();
    // init_cookie_directive();
    //displayPepite();
    init_old_browser();
    init_traffic();
    init_tabs_waze();
    init_autoHeight();
    // init_owl_gallery();
    select_newsletter();
    init_slidedown();
    init_movePremenu();
    init_scrollspy();
    init_zoom_image();
    init_limit_connect();
    closeAlert();

    if (window.matchMedia('(min-width: 700px)').matches) {
      init_owl_carousel();
      init_edge_display_contents();
    }

    init_gallery();
    init_google_optimizer();
  };

  // onDomLoaded
  $(function() {
    main();
  });

})(window.rossel.jquery);
