(function($) {

  // dom vars
  var articles_sidenav_links;

  var isMdLg = function() {
    return window.matchMedia("(min-width: 992px)").matches;
  };

  var init_dom_vars = function() {
    articles_sidenav_links = $('.gr-article-prev-next a');
  };

  var create_article_nav_on_hover_events = function() {
    articles_sidenav_links.each(function(index) {
      var link = articles_sidenav_links.eq(index);
      var link_title = link.find('.gr-article-prev-next-title');

      var onOver = function() {
        // set the correct display class for the highlight
        link_title.addClass('gr-article-prev-next-title-visible');

        // trick to have animation on the first hover
        link_title.hide();
        link_title.fadeIn()
      };

      var onOut = function() {
        link_title.fadeOut();
      };

      link.hover(onOver, onOut);
    });
  };
  
  var new_top_from_footer = function(){
    if (typeof(articles_sidenav_links) != 'undefined'){
      var sidenav_arrow = ($(document).height()) - ($('#gr-footer').height());
      $(window).on('scroll', function () {
        var new_top = sidenav_arrow - Math.max(sidenav_arrow, $(this).scrollTop() + ($(window).height() / 2) +($('#gr-footer').height()/2)); 
        articles_sidenav_links.css("top", new_top);
      });
    }
  }

  var create_events = function() {
    create_article_nav_on_hover_events();
  };
  
  var main = function() {
    if (isMdLg()) {
      init_dom_vars();
      create_events();
      new_top_from_footer();
    }
  };

  // on domloaded
  $(function() {
    main();
  });

})(window.rossel.jquery);
