(function ($) {

  var props = {};

  var init_props = function(options) {
    props = $.extend({
      body: $('body')
    }, props, options);
  };

  var create_events = function(overlay) {
    var bkg = overlay.find(".overlay__bkg");
    var close_btn = overlay.find(".overlay__close");

    bkg.on('click', function (e) {
      e.preventDefault();
      hide_overlay(overlay.data('name'));
    });

    close_btn.on('click', function (e) {
      e.preventDefault();
      hide_overlay(overlay.data('name'));
    });
  };

  var show_overlay = function (name, content, title) {
    var overlay = $('.overlay--' + name);

    if(overlay.exists()) {
      overlay.addClass('is-animated');
      overlay.find('.overlay__content').html(content);
    } else {
      overlay = $('<div class="overlay overlay--' + name + ' is-animated">' +
        '<div class="overlay__bkg"></div>' +
          '<div class="overlay__wrapper">' +
            ((title !== undefined) ? '<div class="overlay__title">' + title + '</div>' : '') +
            '<a class="overlay__close"><i class="icon icon--close fa fa-times"></i></a>' +
            '<div class="overlay__content">' + content + '</div>' +
          '</div>' +
        '</div>');
      props.body.append(overlay);

      create_events(overlay);
    }

    overlay.data('name', name);

    setTimeout(function(){
      overlay.addClass('is-visible');
      props.body.addClass("has-overlay");
    }, 25);

  };

  var hide_overlay = function (name) {
    var overlay = $('.overlay--' + name);

    if(overlay.exists()) {

      overlay.one($.support.transition.end, function(e) {
        overlay.removeClass('is-animated');
        overlay.removeClass('is-hide');
        props.body.removeClass("has-overlay");
      });

      overlay.removeClass('is-visible');
      overlay.addClass('is-hide');
    }
  };

  var main = function(options) {
    init_props(options);
    window.show_overlay = show_overlay;
    window.hide_overlay = hide_overlay;
  };

  $(function(){
    main({});
  });

})(window.rossel.jquery);
