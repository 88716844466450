//----------------------------------------
// Article: lecture Zen
// 20150226
// zen reading managment
//----------------------------------------

(function ($) {
  var zen_traduction = ((((Drupal || '').settings || '').enabootstrap || '').zen_traduction || '');

  var class_body = 'has-zen-reader';
  var class_article = 'is-zen-reader';
  var link_zenify_link_close_content = '<span class="glyphicon glyphicon-eye-close"></span>' + zen_traduction;
  var link_zenify_item = $('.article-features-zen-reading');
  var link_zenify_link = $('.article-features-zen-reading a');
  var link_zenify_link_open_content = link_zenify_link.html();
  var link_zenify_toolbar_social = $('.gr-social-links').html();
  var link_zenify_toolbar_content = '<div class="gr-zen-toolbar"><a href="/" class="home">Le Soir</a>' + link_zenify_toolbar_social + '</div>';
  var article_to_zenify = $('#gr-article article');
  var displaying_zen_mode = false;
  var document_body = $("body");
  var html_body = $("html, body");
  var win = $(window);

  var show_zen_mode = function (options) {

    html_body.scrollTop(0);
    displaying_zen_mode = true;
    document_body.addClass(class_body);
    article_to_zenify.addClass(class_article);
    link_zenify_link.html(link_zenify_link_close_content);
    document_body.prepend(link_zenify_toolbar_content);

    if (window.gEna) {
      window.gEna.doGalleries();
    } else {
      console.log('!!! need window.gEna in zen-reading.js !!!');
    }
  };

  var hide_zen_mode = function () {
    displaying_zen_mode = false;

    document_body.removeClass(class_body);
    article_to_zenify.removeClass(class_article);
    $('.gr-zen-toolbar').remove();
    link_zenify_link.html(link_zenify_link_open_content);
  };

  var toggle_display_zen_mode = function () {
    if (window.matchMedia("(min-width: 992px)").matches) {
      if (displaying_zen_mode) {
        hide_zen_mode();
      } else {
        show_zen_mode();
      }
    }
  };

  var init_zen_mode = function () {
    link_zenify_item.on("click", function (e) {
      $(this).blur();
      e.preventDefault();
      toggle_display_zen_mode();
    });

    win.on("resize", function () {
      if (!window.matchMedia("(min-width: 992px)").matches) {
        hide_zen_mode();
      }
    });

  };

  init_zen_mode();
})(window.rossel.jquery);
