(function($) {
  $(function() {

    var galleries = $('.blueimp-gallery');
    var clone_gallery_container = $('#gr-main-content');
    var cloned_gallery_id = 'cloned_gallery';
    var cloned_gallery;

    var add_controls = function() {
      galleries.addClass('blueimp-gallery-carousel blueimp-gallery-controls');
    };

    var init_gallery_props = function(gallery, index) {
      var gallery_parent = gallery.parent();
      var gallery_id = ['gallery', 'blueimp', index].join('_');
      var gallery_thumbnails = gallery_parent.find('.gr-gallery-thumbnail');
      var gallery_thumbnails_links = gallery_thumbnails.find('a');

      var gallery_captions = [];
      gallery_thumbnails_links.each(function(index) {
        gallery_captions.push(gallery_thumbnails_links.eq(index).attr('title'));
      });

      // remove caption from the slide
      gallery.find('h3.title').remove();

      gallery.props = {
        id: gallery_id,
        selector: '#' + gallery_id,
        index: index,
        parent: gallery_parent,

        thumbs: gallery_thumbnails,
        thumbs_links: gallery_thumbnails_links,
        captions: gallery_captions,
        prev: gallery.find('a.prev'),
        next: gallery.find('a.next'),
      };
    };

    var set_gallery_id = function(gallery) {
      gallery.attr({ 'id': gallery.props.id });
    };

    var add_fullscreen_link = function(gallery) {
      var link_fullscreen = $('<a class="fullsize-toggle fa fa-arrows-alt gr-gallery-ui" />');
      gallery.append(link_fullscreen);
      gallery.props.link_fullscreen = link_fullscreen;
    };

    var create_external_caption = function(gallery) {
      var caption = $('<div class="gr-caption gr-caption-carousel">caption</div>');
      caption.insertAfter(gallery);
      gallery.props.caption = caption;
    };

    var avoid_default_behavior_for_click = function(slide) {
      slide.off('click').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
      });
    };

    var create_blueimp_object = function(gallery) {

      var blueimp_gallery = blueimp.Gallery(gallery.props.thumbs_links, {
        container: gallery.props.selector,
        carousel: true,
        useBootstrapModal: false,
        disableScroll: false,
        enableKeyboardNavigation: false,
        hidePageScrollbars: false,
        startSlideshow: false,
        clearSlides: true,
        stretchImages: true,
        closeOnEscape: true,
        index: 0,

        onslide: function(index, slide) {

          var slide = $(slide);
          avoid_default_behavior_for_click(slide);
          remove_iframe_video_player_if_exists(gallery);

          // set active thumbs
          gallery.props.thumbs.removeClass('active');
          gallery.props.thumbs.eq(index).addClass('active');

          // display caption
          gallery.props.caption.html(get_caption(gallery, index));

          init_video_player_if_needed(gallery, index, slide);

          // GTM
          console.log('GTM test');
          imageURL = $('.gr-gallery-thumbnails', this).eq(index).find('a').attr('href');

          if (typeof ga === 'function') {
            ga('send', 'event', 'Photo', 'click', $(this).attr('href'), { 'nonInteraction': false });
            ga('send', 'event', 'Photo', 'click', imageURL, { 'nonInteraction': false });
          }

          window.viewtiful && window.viewtiful.action('gallery');
        },
      });

      gallery.props.blueimp = blueimp_gallery;
    };

    var init_video_player_if_needed = function(gallery, index, slide) {
      var video_url = gallery.props.thumbs_links.eq(index).data('video_url');

      if (video_url) {
        slide.addClass('gr-gallery-video-item');
        create_icon_video_player(slide, video_url);
      }
    };

    var remove_iframe_video_player_if_exists = function(gallery) {
      var iframe = gallery.find('.injected-iframe');
      if (iframe.exists()) {
        iframe.parent().removeClass('video-content video-playing');
        iframe.remove();
      }
    };

    var remove_icon_video_player_if_exists = function(slide) {
      var icon = slide.find('.gr-gallery-video-item__play-button');
      if (icon.exists()) {
        icon.remove();
      }
    };

    var create_icon_video_player = function(slide, video_url) {
      remove_icon_video_player_if_exists(slide);

      // add icon
      var icon = $('<div class="gr-gallery-video-item__play-button" />');
      slide.prepend(icon);

      slide.off('click').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        inject_player(slide, video_url);
        icon.remove();
      });

    };

    var inject_player = function(slide, video_url) {
      var slide_content = slide.find('.slide-content');
      var iframe = $('<iframe class="injected-iframe" src="' + video_url + '" style="width: 100%; height: 100%" />');
      slide_content.append(iframe);
    };

    var get_caption = function(gallery, index) {
      return gallery.props.captions[index];
    };

    var create_thumbs_events = function(gallery) {
      gallery.props.thumbs.each(function(index) {
        var thumb = gallery.props.thumbs.eq(index);
        thumb.on('click', function(e) {
          e.preventDefault();
          gallery.props.blueimp.slide(index);
        });
      });
    };

    var create_navigation_events = function(gallery) {
      // nav next
      gallery.props.next.on('click', function(e) {
        e.preventDefault();
        gallery.next();
      });

      // nav prev
      gallery.props.prev.on('click', function(e) {
        e.preventDefault();
        gallery.prev();
      });
    };

    var kill_clone_gallery = function() {
      cloned_gallery = $('#' + cloned_gallery_id);

      if (cloned_gallery.exists()) {
        cloned_gallery.remove();
      }
    };

    var create_clone_gallery = function(gallery) {
      var cloned_gallery = gallery.clone();
      var clone_caption = $('<div class="gr-caption"></div>');

      cloned_gallery
        .attr('id', cloned_gallery_id)
        .removeClass('blueimp-gallery-carousel')
        .addClass('blueimp-clone')
        .append(clone_caption);

      cloned_gallery.props = {};

      clone_gallery_container
        .append(cloned_gallery)
        .ready(function() {
          cloned_gallery.props.blueimp = blueimp.Gallery(gallery.props.thumbs_links, {
            useBootstrapModal: false,
            carousel: false,
            container: '#' + cloned_gallery_id,
            disableScroll: true,
            enableKeyboardNavigation: true,
            hidePageScrollbars: true,
            startSlideshow: false,
            clearSlides: true,
            stretchImages: true,
            fullScreen: false,
            index: gallery.props.blueimp.getIndex(),

            onclose: function() {
              remove_iframe_video_player_if_exists(cloned_gallery);

              // display the "current" of the clone to the gallery
              var index = cloned_gallery.props.blueimp.getIndex();
              gallery.props.blueimp.slide(index);

              // force slide to redraw if we do not navigate yet in the clone gallery (display the icon video if needed)
              if (index === gallery.props.blueimp.getIndex()) {
                gallery.props.blueimp.next();
                gallery.props.blueimp.prev();
              }
            },

            onslide: function(index, slide) {
              var slide = $(slide);
              avoid_default_behavior_for_click(slide);
              remove_iframe_video_player_if_exists(cloned_gallery);
              clone_caption.html(get_caption(gallery, index));
              init_video_player_if_needed(gallery, index, slide);
            },
          });
        });
    };

    var create_fullscreen_events = function(gallery) {
      gallery.props.link_fullscreen.on('click', function(e) {
        remove_iframe_video_player_if_exists(gallery);
        kill_clone_gallery();
        create_clone_gallery(gallery);
      });
    };

    var main = function() {
      add_controls();

      galleries.each(function(index) {
        var gallery = galleries.eq(index);

        init_gallery_props(gallery, index);
        set_gallery_id(gallery);
        add_fullscreen_link(gallery);
        create_external_caption(gallery);
        create_blueimp_object(gallery);
        create_navigation_events(gallery);
        create_thumbs_events(gallery);
        create_fullscreen_events(gallery);
      });
    };

    main();

    // to be called in other script
    window.createGalleries = main;
  });
})(window.rossel.jquery);
