(function($){

  $.fn.scrollStop = function (callback) {
    var $this = $(this);
    var self = this;

    $this.scroll(function () {
      if ($this.data('scrollTimeout')) {
        clearTimeout($this.data('scrollTimeout'));
      }
      $this.data('scrollTimeout', setTimeout(callback, 250, self));
    });
  };

})(window.rossel.jquery);
