var selligent_exec = function() {
  log('tag', 'initialize -> init_selligent', window.selligentScripts);

  if (window.selligentScripts && window.selligentScripts.length > 0) {
    window.selligentScripts.forEach(function(handler) {
      handler($);
    });
    window.selligentScripts = [];
  }
};
window.selligent_exec = selligent_exec;

selligent_exec();
