/**
 * NodeList.prototype.forEach() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */
if ('NodeList' in window && !NodeList.prototype.forEach) {
  console.info('polyfill nodelist forEach for IE11');
  NodeList.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

/**
 * Array.prototype.forEach() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!Array.prototype.forEach) {
  console.info('polyfill array forEach for IE11');
  Array.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
