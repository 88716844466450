(function($) {

  var debug = false;
  var menu;
  var items;
  var items_to_inject = '.gr-custom-sections-plus-top-to-inject li';
  var menu_where_inject = '#gr-sections-plus-top';

  var init_props = function() {
    debug && console.log('inject-custom-section-more -> init_props');
    items = $(items_to_inject).reverse();
    menu = $(menu_where_inject);
  };

  var inject = function() {
    debug && console.log('inject-custom-section-more -> inject');
    if (items.exists()) {
      items.each(function(index) {
        var item = items.eq(index);
        menu.prepend(item);
      });
    }
  };

  function main() {
    debug && console.log('inject-custom-section-more -> main');
    init_props();
    inject();
  }

  $(function() {
    main();
  });

})(window.rossel.jquery);
