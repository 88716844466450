//----------------------------------------
// ANIMATED VERTICAL MENU NAVIGATION
// 20150408
//----------------------------------------

(function($){
  // define plugin
  $.fn.verticalCollapseMenu = function (options) {
    // This is the easiest way to have default options.
    var settings = $.extend({
      class_item_with_subnav     : "expanded",
      class_item_with_subnav_open: "has-nav-open",
      class_btn_subnav           : "btn-nav",
      class_nav                  : "nav",
      class_nav_open             : "is-open",
      close_brother_on_open      : false
    }, options);

    var $this       = this;
    var first_click = true;

    var VerticalCollapseMenu = {

      init: function () {

        var items_with_nav = $(VerticalCollapseMenu.classify(settings.class_item_with_subnav));

        items_with_nav.each(function () {
          var li      = $(this);
          var btn_nav = li.children(VerticalCollapseMenu.classify(settings.class_btn_subnav));
          var menu    = li.children(VerticalCollapseMenu.classify(settings.class_nav));

          btn_nav.off('click')
                 .on("click", function () {
                   VerticalCollapseMenu.toggleMenu(menu, li);
                 });
        });
      },

      initAllAlreadyOpenedMenu: function () {
        var lis_openened = $this.find(VerticalCollapseMenu.classify(settings.class_item_with_subnav_open));

        var prev_height = 0;
        $(lis_openened.get()
                      .reverse())
          .each(function (index) {
            var li   = $(this);
            var menu = li.children(VerticalCollapseMenu.classify(settings.class_nav_open));
            VerticalCollapseMenu.initDataHeight(menu);
            var h = menu.innerHeight();
            menu.css("height", h);
          });
      },

      toggleMenu: function (menu, li) {
        if (first_click) {
          VerticalCollapseMenu.initDataHeight($this);
          $this.css("height", $this.innerHeight());

          VerticalCollapseMenu.initAllAlreadyOpenedMenu();
          first_click = false;
        }

        var height_item = VerticalCollapseMenu.getMenuItemHeight(menu);
        var open        = li.hasClass(settings.class_item_with_subnav_open);

        VerticalCollapseMenu.setClass(!open, menu, li);
        VerticalCollapseMenu.showMenu(!open, menu, li);
      },

      showMenu: function (open, menu, li) {
        if (open) {

          if (settings.close_brother_on_open) {
            var brother_opened_li   = li.siblings()
                                        .filter(VerticalCollapseMenu.classify(settings.class_item_with_subnav_open))
                                        .first();
            var menu_brother_opened = brother_opened_li.children(VerticalCollapseMenu.classify(settings.class_nav_open))
                                                       .first();
            VerticalCollapseMenu.setClass(false, menu_brother_opened, brother_opened_li)
            VerticalCollapseMenu.showMenu(false, menu_brother_opened, brother_opened_li);
          }

          VerticalCollapseMenu.openMenu(menu);

        } else {
          VerticalCollapseMenu.closeMenu(menu);
        }
      },

      getOpenedMenuHeight: function (menu) {
        var my_lis           = menu.children("li");
        var menu_open_height = 0;
        var opened_menus     = my_lis.find(VerticalCollapseMenu.classify(settings.class_nav_open));

        opened_menus.each(function () {
          var opened_menu         = jQuery(this);
          var closed_parents_menu = opened_menu.parents(VerticalCollapseMenu.classify(settings.class_nav))
                                               .not(VerticalCollapseMenu.classify(settings.class_nav_open));

          if (closed_parents_menu.length == 1) {
            menu_open_height += VerticalCollapseMenu.numberify(opened_menu.data("menu-height"));
          }

        });

        return menu_open_height;
      },

      getMenuHeight: function (menu) {
        var menu_open_height = VerticalCollapseMenu.getOpenedMenuHeight(menu);
        var height           = "auto";
        return height;
      },

      setMenusHeight: function (menu, menu_height) {
        // menu itself
        menu.css("height", VerticalCollapseMenu.pixelfy(menu_height));

        // parents menu
        menu.parents(VerticalCollapseMenu.classify(settings.class_nav))
            .each(function (index) {
              var menu_parent = jQuery(this);
              var new_height  = VerticalCollapseMenu.getMenuHeight(menu_parent);
              menu_parent.css({height: VerticalCollapseMenu.pixelfy(new_height)});
            });
      },

      openMenu: function (menu) {
        VerticalCollapseMenu.setMenusHeight(menu, VerticalCollapseMenu.getMenuHeight(menu));
      },

      closeMenu: function (menu) {
        var menu_height = menu.innerHeight();

        menu.parents(VerticalCollapseMenu.classify(settings.class_nav))
            .each(function (index) {
              var menu_parent = jQuery(this);
              var new_height  = "auto";
              menu_parent.css({height: VerticalCollapseMenu.pixelfy(new_height)})
            });

        menu.css("height", 0);
      },

      initDataHeight: function (menu) {
        var first_li = menu.children("li")
                           .not('.has-nav-open')
                           .first();
        var item_height  = first_li.innerHeight();

        menu.data("item-height", item_height);
        //menu.data("menu-height", (item_height * menu.children("li").length) + menu[0].offsetHeight);
        menu.data("menu-height", "auto");

        return item_height;
      },

      getMenuItemHeight: function (menu) {
        var menu_item_height = menu.data("item-height");
        if (menu_item_height) {
          return menu_item_height;
        } else {
          return VerticalCollapseMenu.initDataHeight(menu);
        }
      },

      setClass: function (open, menu, li) {
        if (open) {
          menu.addClass(settings.class_nav_open);
          li.addClass(settings.class_item_with_subnav_open);
        } else {
          menu.removeClass(settings.class_nav_open);
          li.removeClass(settings.class_item_with_subnav_open);
        }
      },

      classify: function (class_name) {
        return "." + class_name;
      },

      pixelfy: function (value) {
        if (value == "auto") {
          return "auto";
        }
        return value + "px";
      },

      numberify: function (value) {
        if (value == "auto") {
          return "auto";
        }
        value = parseInt(value);
        return isNaN(value) ? 0 : value;
      }
    };

    VerticalCollapseMenu.init();

    return this;
  };

})(window.rossel.jquery);
