if (Array.prototype.filter === undefined) {
  Array.prototype.filter = function(fn) {
    var rv = [];

    var l = this.length;
    for(var i = 0; i < l; i++){
      if (fn(this[i])) {
        rv.push(this[i]);
      }
    }

    return rv;
  };
}