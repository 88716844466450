//----------------------------------------
// Global: Sidebar Left (former le-direct)
//----------------------------------------

(function($) {
    window.le_direct_start = function() {
        var sidebar_left = $('#gr-sidebar-left');
        var tap_button = sidebar_left.find(
            '.gr-le-direct-tap, .panel-heading, .gr-search-facet h2'
        );
        var open_button = $('.gr-le-direct-open');
        var swipe_objects = $(
            '#gr-une-bis-content, .gr-article-header, .gr-article-content, .gr-article-sidebar, #comments, #gr-footer, #gr-sidebar-left'
        );

        // add tap event
        tap_button.hammer().on('tap', function(e) {
            e.preventDefault();
            sidebar_left.toggleClass('is-visible');
        });

        open_button.hammer().on('tap', function(e) {
            e.preventDefault();
            sidebar_left.addClass('is-visible');
        });

        // add swipe event when xssm
        if (window.matchMedia('(max-device-width: 1199px)').matches) {
            swipe_objects.hammer().on('swiperight', function(e) {
                e.preventDefault();
                sidebar_left.addClass('is-visible');
            });

            swipe_objects.hammer().on('swipeleft', function(e) {
                e.preventDefault();
                sidebar_left.removeClass('is-visible');
            });
        }

        // ------------------------------------------
        // ONLY FOR ENA LESOIR
        // TODO -> MOVE TO MAIN.JS OF ENA LE SOIR
        // ONLY FOR ENA LESOIR
        // ------------------------------------------

        var $full_page_width_le_direct = $('body.gr-full-width-page #gr-le-direct');

        if ($full_page_width_le_direct.exists()) {
            var create_tools_for_full_width_page = function() {
                // remove the sidenav in full width page
                $('#gr-articles-sidenav').remove();

                // create the tools container
                $('<div/>')
                    .attr({
                        id: 'gr-full-width-page-tools',
                        class: 'btn-group-vertical',
                        role: 'group',
                        'aria-label': '.....'
                    })
                    .appendTo(sidebar_left);

                // create the tool "btn display left" - show the #gr-sidebar-left
                $('<button/>')
                    .html('<span class="fa fa-plug gr-togglers"></span>')
                    .attr({
                        id: 'btn-display-panel-left',
                        type: 'button',
                        class: 'btn btn-default gr-toggle-button',
                        title: 'Voir le direct',
                        'data-placement': 'right',
                        'data-toggle': 'tooltip'
                    })
                    .click(function() {
                        var btn = $(this);
                        btn.tooltip('hide');
                        sidebar_left.toggleClass('is-open');

                        if (sidebar_left.hasClass('is-open')) {
                            btn
                                .html(
                                    '<span class="fa fa-close gr-togglers"></span>'
                                )
                                .attr('title', 'Fermer le direct')
                                .attr(
                                    'data-original-title',
                                    'Fermer le direct'
                                );
                        } else {
                            btn
                                .html(
                                    '<span class="fa fa-plug gr-togglers"></span>'
                                )
                                .attr({
                                    title: 'Voir le direct',
                                    'data-original-title': 'Voir le direct'
                                });
                        }
                    })
                    .appendTo($('#gr-full-width-page-tools'));
            };

            create_tools_for_full_width_page();
        }
    };
})(window.rossel.jquery);
