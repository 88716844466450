(function() {
  window.initDataLayer && window.initDataLayer();
  window.createStyle && window.createStyle('.async-hide {opacity: 0 !important}');

  // init function to be called in initialize
  window.init_google_optimize = function(selectorForElementsToHide, duration, config) {
    // default value
    selectorForElementsToHide = selectorForElementsToHide || 'body';
    duration = duration || 4000;
    config = config || { 'GTM-XRMG': true };

    // get elements
    var elementsToHide = Array.from(document.body.querySelectorAll(selectorForElementsToHide));

    // show/hide methods
    var toggleClass = function(bool) {
      var action = bool ? 'add' : 'remove';
      elementsToHide.forEach(function(el) {
        el.classList[action]('async-hide');
      });
    };

    // hide elements
    toggleClass(true);

    // create end func on config
    config.end = function() {
      toggleClass(false);
    };

    // create hide function of data layer
    window.dataLayer.hide = config;

    // start timeout
    setTimeout(function() {
      toggleClass(false);
      config.end = null;
    }, duration);
  };
})();

// initial google script
// (function(a, s, y, n, c, h, i, d, e) {
//   // hide
//   s.className += ' ' + y;
//
//   // create end func in config
//   h.end = i = function() {
//     s.className = s.className.replace(RegExp(' ?' + y), '');
//   };
//
//   // passing config to data layer
//   (a[n] = a[n] || []).hide = h;
//
//   // start time out
//   setTimeout(function() {
//     i();
//     h.end = null;
//   }, c);
//
// })(window, document.documentElement, 'async-hide', 'dataLayer', 4000, { 'GTM-XRMG': true });
