//----------------------------------------
// Before/After Print Event -> load image (because of lazy load img do not have src)
// 20150408
//----------------------------------------
(function ($) {
  $(function(){
    var beforePrint = function () {
      //console.log('Functionality to run before printing.');
      $('img.lazy').each(function () {
        var img = $(this);
        img.attr("src", img.data("original"));
      });
    };

    var afterPrint = function () {
      //console.log('Functionality to run after printing');
    };

    if (window.matchMedia) {
      var mediaQueryList = window.matchMedia('print');
      if (mediaQueryList.addListener) {
        mediaQueryList.addListener(function (mql) {
          if (mql.matches) {
            beforePrint();
          } else {
            afterPrint();
          }
        });
      }
    }

    window.onbeforeprint = beforePrint;
    window.onafterprint  = afterPrint;
  });
})(window.rossel.jquery);
