//----------------------------------------
// Article main media fixed
//----------------------------------------
(function($) {
  var $el = $('.gr-main-media__wrapper');
  var win = $(window);

  var fixedDiv = function() {
    var top = $el.offset().top + 100;
    if (win.scrollTop() > top) {
      $el.addClass('gr-main-media--fixed').find('.gr-caption').addClass('visible');
    } else {
      $el.removeClass('gr-main-media--fixed');
    }
  };

  if ($el.exists()) {
    if (window.matchMedia("(min-width: 1200px)").matches) {
      win.scroll(fixedDiv);
      fixedDiv();
    } else {
      $el.removeClass('gr-main-media--fixed');
    }
  }

})(window.rossel.jquery);
