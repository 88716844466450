function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

function getJwtJson() {
  let jwtToken = (typeof JWTTokenName !== 'undefined') ? JWTTokenName : 'jwtToken';
  let cookie = window.getCookie(jwtToken);

  if (cookie) {
    return parseJwt(cookie);
  } else {
    return false;
  }
}

const rossel_sso = getJwtJson();
let body = document.querySelector('body');
// window.carbon.sso = 'anonyme';

document.querySelectorAll('.js-sso--url').forEach(elem => {
  if (typeof JWTTokenName !== 'undefined') {
    elem.href = elem.href.replace(JSReturnPageTokenName, window.location.href);
  }
});

if (rossel_sso && rossel_sso.contact) {
  body.classList.add('r-connecte');
  body.classList.remove('r-anonyme');

  document.querySelectorAll('.js-sso--user').forEach(elem => {
    elem.innerHTML = rossel_sso.contact.fullName;
  });

  // let rossel_user_picto = document.querySelector('.r-sso--picto');
  // rossel_user_picto.innerHTML = rossel_sso.contact.firstName.charAt(0) + rossel_sso.contact.lastName.charAt(0);

  if (rossel_sso.accessList.ena || rossel_sso.accessList["light-ena"]) {
    body.classList.add('r-abonne');
    // window.carbon.sso = 'abonne';
  } else {
    body.classList.add('r-connecte-sd');
    // window.carbon.sso = 'connecte';
  }
}

body.classList.add('r-sso--loaded');
