window.createStyle = function(cssRules) {
  var style = document.createElement('style');
  style.type = 'text/css';

  if(style.styleSheet) {
    style.styleSheet.cssText = cssRules;
  } else {
    style.appendChild(document.createTextNode(cssRules));
  }

  document.body.prepend(style);
};
