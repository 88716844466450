window.init_gallery_owl = function(options) {

  console.log('gallery-owl -> init_gallery_owl', options);

  var defaultOptions = {
    container: 'html',
    galleries: '.gallery[data-carousel]',
    carousels: '.gallery__preview',
    thumbs: '.gallery__thumbs',
    thumbItem: '.gallery__thumb-item',
    fullscreens: '.gallery__btn-fullscreen',
    fullScreenIcon: '.icon',
    nav: '.gallery__nav',
    next: '.gallery__next',
    previous: '.gallery__prev',
    iconExpand: 'icon-carousel-expand',
    iconClose: 'icon-carousel-close',

    callback: function(owlEvent) {
      console.log('gallery-owl -> callback : need to define a callback function in initialize.js ', owlEvent.page);
    },

    carousel: {
      items: 1,
      loop: true,
      nav: false,
      dots: true, // to get page.index updated in owlEvent
      merge: false,
      video: true,
    },
  };

  options = Object.assign({}, defaultOptions, options);

  var win = $(window);
  var container = $(options.container);
  var galleries = $(options.galleries);
  var carousels = galleries.find(options.carousels);
  var navs = galleries.find(options.nav);
  var thumbnails = galleries.find(options.thumbs);
  var fullscreens = galleries.find(options.fullscreens);
  var activeCarousel = null;

  galleries.each(function(index) {
    var gallery = galleries.eq(index);
    var carousel = carousels.eq(index);
    var nav = navs.eq(index);
    var thumbnailsItems = thumbnails.eq(index).find(options.thumbItem);
    var fullscreen = fullscreens.eq(index);
    var fullscreenIcon = fullscreens.find(options.fullScreenIcon);
    var prevBtn = nav.find(options.previous);
    var nextBtn = nav.find(options.next);

    var activeThumbItem = function(index) {
      if (index >= 0) {
        thumbnailsItems.removeClass('is-active');
        thumbnailsItems.eq(index).addClass('is-active');
      }
    };

    var goto = function(index) {
      carousel.trigger('to.owl.carousel', [index]);
    };

    var prev = function() {
      carousel.trigger('prev.owl.carousel');
    };

    var next = function() {
      carousel.trigger('next.owl.carousel');
    };

    var setNavPosition = function() {
      nav.css({ top: (gallery.find('.owl-item img').first().height() / 2) + 'px' });
      if (parseInt(nav.css('top')) === 0) { // ensure to get a right top  (sometimes img height is 0)
        setTimeout(setNavPosition, 300);
      }
    };

    var toggleFullScreen = function() {
      fullscreenIcon.toggleClass(options.iconExpand);
      fullscreenIcon.toggleClass(options.iconClose);
      container.toggleClass('has-gallery-fullscreen');
      gallery.toggleClass('gallery--fullscreen');
      carousel.trigger('refresh.owl.carousel');

      activeCarousel = null;
      if (container.hasClass('has-gallery-fullscreen')) {
        activeCarousel = carousel;
      }

      setNavPosition();
    };

    var initialize = function() {
      setNavPosition();
      gallery.addClass('gallery--initialized');
    };

    carousel.toggleFullScreen = toggleFullScreen;
    carousel.next = next;
    carousel.prev = prev;

    // INIT OWL CAROUSSEL

    carousel.owlCarousel(options.carousel);

    // LISTEN OWL CAROUSSEL EVENTS

    carousel.on('changed.owl.carousel', function(owlEvent) {
      var index = owlEvent.page.index;
      activeThumbItem(index);
      options.callback && options.callback(owlEvent);
    });

    carousel.on('resize.owl.carousel', function(owlEvent) {
      setNavPosition();
    });

    win.on('resize', function() {
      setNavPosition();
    });

    // DOM EVENTS

    thumbnailsItems.each(function(index) {
      var thumbnailsItem = thumbnailsItems.eq(index);
      thumbnailsItem.on('click', function(e) {
        goto(index);
      });
    });

    prevBtn.on('click', prev);
    nextBtn.on('click', next);
    fullscreen.on('click', toggleFullScreen);

    // INIT

    initialize();

  });

  container.on('keyup', function(e) {
    if (activeCarousel) {
      switch (e.keyCode) {
        // ESC
        case 27:
          activeCarousel.toggleFullScreen();
          break;

        // ARROW LEFT
        case 37:
          activeCarousel.prev();
          break;

        // ARROW RIGHT
        case 39:
          activeCarousel.next();
          break;
      }
    }
  });

};
