/* ========================================================================
 * Bootstrap: sidebar.js v0.1
 * ========================================================================
 * Copyright 2011-2014 Asyraf Abdul Rahman
 * Licensed under MIT
 * ======================================================================== */

+(function($) {
  'use strict';

  // SIDEBAR PUBLIC CLASS DEFINITION
  // ================================

  var Sidebar = function(element, options) {
    this.$element = $(element);
    this.options = $.extend({}, Sidebar.DEFAULTS, options);
    this.transitioning = null;

    if (this.options.parent) this.$parent = $(this.options.parent);
    if (this.options.toggle) this.toggle();
  };

  Sidebar.DEFAULTS = {
    toggle: true,
  };

  Sidebar.prototype.show = function() {
    if (this.transitioning || this.$element.hasClass('sidebar-open')) return;

    var startEvent = $.Event('show.bs.sidebar');
    this.$element.trigger(startEvent);
    if (startEvent.isDefaultPrevented()) return;

    this.$element.addClass('sidebar-open');

    this.transitioning = 1;

    var complete = function() {
      this.$element;
      this.transitioning = 0;
      this.$element.trigger('shown.bs.sidebar');
    };

    if (!$.support.transition) return complete.call(this);

    this.$element
      .one($.support.transition.end, $.proxy(complete, this))
      .emulateTransitionEnd(400);
  };

  Sidebar.prototype.hide = function() {
    if (this.transitioning || !this.$element.hasClass('sidebar-open')) return;

    var startEvent = $.Event('hide.bs.sidebar');
    this.$element.trigger(startEvent);
    if (startEvent.isDefaultPrevented()) return;

    this.$element.removeClass('sidebar-open');

    this.transitioning = 1;

    var complete = function() {
      this.transitioning = 0;
      this.$element.trigger('hidden.bs.sidebar');
    };

    if (!$.support.transition) return complete.call(this);

    this.$element
      .one($.support.transition.end, $.proxy(complete, this))
      .emulateTransitionEnd(400);
  };

  Sidebar.prototype.toggle = function() {
    this[this.$element.hasClass('sidebar-open') ? 'hide' : 'show']();
  };

  var old = $.fn.sidebar;

  $.fn.sidebar = function(option) {
    return this.each(function() {
      var $this = $(this);
      var data = $this.data('bs.sidebar');
      var options = $.extend({}, Sidebar.DEFAULTS, $this.data(), typeof options == 'object' && option);

      if (!data && options.toggle && option == 'show') option = !option;
      if (!data) $this.data('bs.sidebar', (data = new Sidebar(this, options)));
      if (typeof option == 'string') data[option]();
    });
  };

  $.fn.sidebar.Constructor = Sidebar;

  $.fn.sidebar.noConflict = function() {
    $.fn.sidebar = old;
    return this;
  };

  $(document).on('click.bs.sidebar.data-api', '[data-toggle="sidebar"]',

    function(e) {

      if (e) {
        e.preventDefault();
      }

      var $this = $(this), href;
      var target = $this.attr('data-target') || e.preventDefault() || ((href = $this.attr('href')) && href.replace(/.*(?=#[^\s]+$)/, ''));
      var $target = $(target);
      var data = $target.data('bs.sidebar');
      var option = data ? 'toggle' : $this.data();

      // 20200312 - needed when add content of the menu on open sidebar event
      var template = $target.find('[data-template-menu-content]');
      if (template.length && window.init_vertical_collapse_menu) {
        var content = $(template.html());
        content.insertBefore(template);
        window.init_vertical_collapse_menu();
        template.remove();
      }

      $target.sidebar(option);
    });

  // touch Fix - instead of $('hml')

  $('#wrapper').on('click.bs.sidebar.autohide', function(event) {
    if ($('body').hasClass('has-sidebar')) {
      if (event && event.originalEvent && event.originalEvent.target) {
        var target = $(event.originalEvent.target);

        // if the link is not in the sidebar
        if (!target.parents('.sidebar').exists()) {
          // do not want to follow the targeted link if a sidebar is open
          event.preventDefault();
        }
      }
    }

    var $this = $(event.target);
    var isButtonOrSidebar = $this.is('.pane-ena-direct, .sidebar, [data-toggle="sidebar"]') || $this.parents('.pane-ena-direct, .sidebar, [data-toggle="sidebar"]').length;

    // FIX FOR CHROME EVENT -> ensure we are clicking on sidebar "area"

    var elem = $('#gr-main-nav .ps-scrollbar-y-rail, .pane-ena-direct .ps-scrollbar-y-rail');

    if (elem.exists() && event.clientX) {
      var event_stage_area_gap = 15;
      var event_stage_left = elem.offset().left;
      var event_stage_right = event_stage_left + elem.width();
      var left_bound = event_stage_left - event_stage_area_gap;
      var right_bound = event_stage_right + event_stage_area_gap;
      var event_client_x = event.originalEvent.clientX;
      var is_ps_scroll_area = event_client_x >= left_bound && event_client_x <= right_bound;
    }

    if (isButtonOrSidebar || is_ps_scroll_area) {
      // do nothing if it's the sidebar itself or the button triggering the open/close of the sidebar
      return;
    } else {
      // otherwhise close the opened sidebar
      var $target = $('.sidebar, .pane-ena-direct');
      $target.each(function(i, trgt) {
        var $trgt = $(trgt);

        if ($trgt.data('bs.sidebar') && $trgt.hasClass('sidebar-open')) {
          $trgt.sidebar('hide');
        }
      });
    }
  });
})(window.rossel.jquery);
